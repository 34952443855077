<template>
  <Page has-actions>
    <b-loading
      v-if="loading"
      :is-full-page="false"
      :active="true"
    />
    <!-- <Maintenance /> -->
    <div class="columns is-multiline">
      <!-- <Overview
        class="column is-12 mb-0"
        :overview="overview"
        :loading="loading"
      /> -->
      <Awaiting
        v-if="
          widgets && widgets.awaiting
        "
        class="column is-6 pr-4"       
        :awaiting="awaiting"
        :loading="loading"
      />
      <!-- <Orders
        v-if="$can('read', 'orders')"
        class="column is-7 pr-4"       
        :orders="orders"
      /> -->
    </div>
  </Page>
</template>

<script>
import Page from "@/layout/Page";
import Maintenance from "@/components/Maintenance";
import Awaiting from "@/components/modules/Dashboard/Awaiting";
// import Overview from "@/components/modules/Dashboard/Overview";
// import Orders from "@/components/modules/Dashboard/Orders";

export default {
  components: {
    Page,
    // Maintenance,
    Awaiting,
    // Overview,    
    // Orders
  },
  data() {
    return {
      overview: [],
      widgets: [],
      awaiting: [],
      orders: [],
      loading: false,
    };
  },
  created() {
    this.updateTitle(this.$t("dashboard"));
    this.getItems();
  },
  methods: {
	  getItems() {
      this.loading = true;
      this.$axios
        .get(`/dashboard`)
          .then(res  => { 
           this.widgets = res.data.widgets;
           this.overview = res.data.overview;
           this.awaiting = res.data.widgets.awaiting;
           this.orders = res.data.widgets.orders;         
          })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.loading = false));
    },
  }
};
</script>
